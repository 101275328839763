


.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.message {
	font-size: 2.7rem;
	text-align: center;
	margin-top: .25em;
 font-weight: bold;
	color: #8E857B;
	// -webkit-animation: fade 3s ease-in-out 0s infinite;
	// animation: fade 3s ease-in-out 0s infinite;
}
.subMessage {
	font-size: 3em;
	text-align: center;
	margin-top: .25em;
 font-weight: bold;
	color: #8E857B;
	// -webkit-animation: fade 3s ease-in-out 0s infinite;
	// animation: fade 3s ease-in-out 0s infinite;
}

#diamonds .diamond {
	stroke: #bbb;
}

#diamonds .left {
	stroke: #8E857B;
}

#diamonds .center {
	stroke: #8E857B;
	opacity: .6;
}

#diamonds .right {
	stroke: #8E9090;
	opacity: .6;
}

#diamonds.animate .diamond {

	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	transform-origin: center;
}

#diamonds.animate.fade .diamond {
	-webkit-animation: draw 3s ease-in-out 0s infinite;
	animation: fade 3s ease-in-out 0s infinite;
}

#diamonds.animate .center {
	-webkit-animation-delay: .25s;
  animation-delay: .25s;
}

#diamonds.animate .right {
	-webkit-animation-delay: .5s;
  animation-delay: .5s;
}

#diamonds.animate.fade .center {
	animation: fade2 3s ease-in-out 0s infinite;
	-webkit-animation-delay: .25s;
  animation-delay: .25s;
}

#diamonds.animate.fade .right {
	animation: fade2 3s ease-in-out 0s infinite;
	-webkit-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes draw {
  0%, 80%, 100% {
		stroke-dashoffset: 1000;
  }
	40% { 
		stroke-dashoffset: 0;
  }
}

@keyframes draw {
	0%, 80%, 100% {
		stroke-dashoffset: 1000;
  }
	40% { 
		stroke-dashoffset: 0;
  }
}

@-webkit-keyframes fade {
  0%   { opacity: .5; }
  50%  { opacity: 1; }
  100% { opacity: .5; }
}

@keyframes fade {
  0%   { opacity: 1; }
  50%  { opacity: .1; }
  100% { opacity: 1; }
}

@keyframes fade2 {
  0%   { opacity: .6; }
  50%  { opacity: .1; }
  100% { opacity: .6; }
}